import { FormattedMessage } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';

export default function QuickLinks(props) {
  return (
    <React.Fragment>
      <div className='quick-links-heading tray-heading'>
        <FormattedMessage id={ 'PC:AppHeader:Header:QuickLinks' } />
      </div>
      <div className='quick-links'>{ props.links }</div>
    </React.Fragment>
  );
}

QuickLinks.propTypes = {
  links: PropTypes.array
};
