import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavSet from './nav-set';
import classnames from 'classnames';

class NavigationBottom extends Component {
  constructor() {
    super(...arguments);
  }
  render() {
    const props = this.props;
    return (
      <nav
        className={ classnames(
          'nav-bottom',
          'clearfix'
        ) }>
        <div className='container'>
          <NavSet
            { ...props }
          />
        </div>
      </nav>
    );
  }
}

/**
 * Helper to update navigations (regular or right sided). Clones object or arrays
 * to ensure setState is done against a new object in memory.
 *
 * @param {Array|Object} nav Updated navigation, can be navigation array or object.
 * @param {Boolean} right Optionally update right side navigation, defaults to regular.
 * @param {Function} done Optional completion callback.
 * @returns {Self} fluent interface.
 * @api public
 */
NavigationBottom.update = function update(nav, right = false, done = () => {}) {
  if (typeof right === 'function') {
    done = right;
    right = false;
  }

  const navs = ['navigation', 'navigationRight'];
  const prop = navs[+right];

  if (Array.isArray(nav)) {
    return this.setState(
      {
        [prop]: nav.slice()
      },
      done
    );
  }

  return this.setState(
    navs.reduce((memo, key) => {
      if (Array.isArray(nav[key])) {
        memo[key] = nav[key].slice();
      }

      return memo;
    }, {}),
    done
  );
};

/**
 * Require properties.
 *
 * @type {Object}
 * @api public
 */

const rightNavPropTypes = PropTypes.shape({
  'blacklist': PropTypes.object,
  'whitelist': PropTypes.object,
  'caption': PropTypes.node,
  'data-mix': PropTypes.string,
  'disposition': PropTypes.string,
  'eid': PropTypes.string,
  'href': PropTypes.string,
  'children': PropTypes.array,
  'design': PropTypes.oneOf(['purchase', 'primary', 'default'])
});

NavigationBottom.propTypes = {
  navigation: PropTypes.array.isRequired,
  navigationRight: PropTypes.arrayOf(rightNavPropTypes)
};

export default NavigationBottom;
