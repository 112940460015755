import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import MobileNav from '@ux/mobile-nav';
import { golf } from './enums';
import { isActive } from './utils';

export default function HamburgerMenu(props) {
  const { items, appName, currentHref } = props;
  const activeHeading = React.useMemo(() => {
    let result;
    for (let i = 0; i < items.length; i++) {
      if (isActive(items[i], currentHref)) {
        result = items[i];
        break;
      }
    }
    return result;
  }, [items, currentHref]);

  return (
    <div className='top-nav-set'>
      <MobileNav { ...props }
        nav={ items }
        menuTitle={ appName || <FormattedMessage id={ golf.mainMenu } /> }
        currentHref={ currentHref }
        activeHeading={ activeHeading }
        showHomeLink={ true }
        useActiveHeadingAsMenuTitle={ true }
        showTitle={ false } />
    </div>
  );
}

HamburgerMenu.propTypes = {
  items: PropTypes.array.isRequired,
  appName: PropTypes.string,
  currentHref: PropTypes.string
};
