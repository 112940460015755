import React from 'react';
import AccountDelegation from '@ux/account-delegation';
import { events, Customer, Employee } from '@ux/header-util';
import NavigationBottom from './nav-bottom';
import NavigationTop from './nav-top';
import ApplicationFooter from '@ux/application-footer';
import AbstractHeader, { LazyBrowserDeprecationBanner } from '@ux/abstract-header';
import HeaderComponent from './header';
import assign from 'object-assign';
import PropTypes from 'prop-types';
import Cart from '@ux/cart';
import HubBanner from './hub-banner';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { isBrowser } from '@ux/component-utilities';

/**
 * Render and expose the Header as a functional Component.
 *
 * @param {Object} props Properties
 * @returns {ReactElement} Header.
 * @api public
 */
class Header extends AbstractHeader {
  constructor() {
    super(...arguments);
    this.state = {
      CartComponent: Cart, /* eslint-disable no-unused-vars */
      customWaffleLinks: null,
      helpUrl: null,
      customAccountTrayNav: null,
      replaceAccountTrayNavLinks: false
    };
    this.updateAccountTrayNav = NavigationTop.updateAccountTrayNav.bind(this);
    this.updateCartComponent = NavigationTop.updateCartComponent.bind(this);
    this.updateNavigation = NavigationBottom.update.bind(this);
    this.updateTopNavigation = NavigationTop.update.bind(this);
    this.updateHelpUrl = NavigationTop.updateHelpUrl.bind(this);
    this.updateWaffleLinks = NavigationTop.updateWaffleLinks.bind(this);
    this.determineHref = this.determineHref.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  /**
   * Returns a valid Customer instance using the props associated
   * with this instance. If `internalMode` is `true` returns an `Employee`.
   *
   * @returns {User} Customer or Employee instance from @ux/header-util.
   */
  createCustomer() {
    const props = this.props;

    // Override customer if internal mode
    if (props.preset === 'internal-header') {
      return new Employee(props);
    }

    return new Customer(props);
  }

  /**
   * Prefer state over props when assigning to children
   *
   * @returns {Object} Child props
   * @api private
   */
  getChildProps() {
    const childProps = assign({}, this.props, this.state);
    return childProps;
  }

  /**
   * Determines href string to set on a tag.
   *
   * @param {String} [id] String sent in by user.
   * @returns {String} Href to set on a tag or null if run on the server side
   */
  determineHref(id) {
    if (id) {
    // If they provided an id, just let pass that string as href.
      return `#${id}`;
    }

    // fallback logic
    if (typeof window === 'undefined') {
    // Make sure we're okay for server rendering
      return;

    }
    const focusOn = ['#main-content', '#main', '#root'].find(sel => document.querySelector(sel));
    // If we found something better than our default use it, otherwise let the link handle it
    if (focusOn) {
      return focusOn;
    }

    return '#uxContent';
  }

  render() {
    const childProps = this.getChildProps();
    const {
      market,
      messages,
      urls,
      preset,
      supportMatrix,
      whitelistedUserAgents,
      disableDeprecationBanner,
      blacklistedBrowsers,
      helpUrl,
      skipToMainContentLink
    } = childProps;

    const skipToContentTitle = skipToMainContentLink && skipToMainContentLink.caption ? skipToMainContentLink.caption : <FormattedMessage id='Shared:Common:SkipToMainContent' />;
    const skipToContentSelector = this.determineHref(skipToMainContentLink && skipToMainContentLink.id);

    return (
      <React.Fragment>
        <IntlProvider locale={ market } messages={ messages }>
          <div className={ isBrowser() ? 'loaded' : null }>
            <a href={ skipToContentSelector } className='skip-to-main-content' >
              { skipToContentTitle }
            </a>
            <LazyBrowserDeprecationBanner
              supportMatrix={ supportMatrix }
              whitelistedUserAgents={ whitelistedUserAgents }
              disableDeprecationBanner={ disableDeprecationBanner }
              blacklistedBrowsers={ blacklistedBrowsers }
              urls={ urls } />
            { preset === 'internal-header' ? null : (
              <AccountDelegation
                market={ market }
                messages={ messages }
                action={ urls.sso.exitDelegation.href }
                restoreCookie={ urls.sso.restoreCookie.href }
                customer={ this.customer }
              />
            ) }
            { childProps.privateLabelId === 1 && preset !== 'internal-header' ? (
              <HubBanner
                market={ market }
                messages={ messages }
                href={ urls.proHub.href }
              />
            ) : null }
            <HeaderComponent { ...childProps } />
          </div>
          <FormattedMessage id='Shared:Common:MainContentStarts'>
            {text => (
              <span aria-label={ text } id='uxContent'></span>
            )}
          </FormattedMessage>
        </IntlProvider>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  market: PropTypes.string.isRequired,
  urls: PropTypes.object.isRequired,
  helpUrl: PropTypes.string,
  resellerUrls: PropTypes.object,
  privateLabelType: PropTypes.number,
  privateLabelId: PropTypes.number,
  preset: PropTypes.string,
  traffic: PropTypes.string,
  supportMatrix: PropTypes.object.isRequired,
  whitelistedUserAgents: PropTypes.array,
  disableDeprecationBanner: PropTypes.bool,
  blacklistedBrowsers: PropTypes.arrayOf(
    PropTypes.shape({
      browser: PropTypes.string,
      version: PropTypes.number
    })
  ),
  skipToMainContentLink: PropTypes.shape({
    id: PropTypes.string,
    caption: PropTypes.string
  })
};

Header.defaultProps = {
  preset: 'application-header'
};

/**
 * Render and expose the Footer as a functional Component.
 *
 * @param {Object} props Properties
 * @returns {ReactElement} Footer.
 * @api public
 */
class Footer extends React.Component {
  componentDidMount() {
    events.emit('mount', 'footer', this);
    events.emit('mount:footer', this);
  }

  render() {
    return <ApplicationFooter { ...this.props } />;
  }
}

export { Header, Footer };
