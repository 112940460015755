import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { golf } from './enums';
import Apps from '@ux/icon/apps';
import WaffleMenu from './waffle';
import { withBreakpoints } from './utils';

/**
 * Render Big Three product dropdown.
 *
 * @Constructor
 * @class BigThree
 * @api public
 */
export class AppName extends Component {
  render() {
    const { messages, showWaffleMenu, app, appName } = this.props;

    const appNameOverride = messages[golf.myAccount];
    const name =
      ['riviera', 'mya', 'go', 'account', 'sso'].indexOf(app) >= 0
        ? appNameOverride
        : appName;
    const productName = <span className='product-name'>{ name }</span>;
    let content = productName;

    if (showWaffleMenu) {
      const control = (
        <Fragment>
          <Apps />
          { productName }
        </Fragment>
      );
      content = <WaffleMenu { ...this.props } control={ control } />;
    }

    return content;
  }
}

AppName.propTypes = {
  breakpoint: PropTypes.string,
  urls: PropTypes.object.isRequired,
  app: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  showWaffleMenu: PropTypes.bool
};

export default withBreakpoints(AppName);
