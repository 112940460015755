(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-intl")["default"], require("@ux/util")["default"], require("prop-types"), require("react-dom"), require("@ux/button")["default"], require("@ux/modal")["default"], require("@ux/spinner")["default"], require("@ux/growl")["default"], require("@ux/text")["default"]);
	else if(typeof define === 'function' && define.amd)
		define("ApplicationHeader", ["react", "react-intl", "@ux/util", "prop-types", "react-dom", "@ux/button", "@ux/modal", "@ux/spinner", "@ux/growl", "@ux/text"], factory);
	else if(typeof exports === 'object')
		exports["ApplicationHeader"] = factory(require("react"), require("react-intl")["default"], require("@ux/util")["default"], require("prop-types"), require("react-dom"), require("@ux/button")["default"], require("@ux/modal")["default"], require("@ux/spinner")["default"], require("@ux/growl")["default"], require("@ux/text")["default"]);
	else
		root["ApplicationHeader"] = factory(root["React"], root["ux"]["intl"], root["ux"]["utils"], root["PropTypes"], root["ReactDOM"], root["ux"]["Button"], root["ux"]["Modal"], root["ux"]["Spinner"], root["ux"]["Growl"], root["ux"]["Text"]);
})(self, (__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_intl__, __WEBPACK_EXTERNAL_MODULE__ux_util__, __WEBPACK_EXTERNAL_MODULE_prop_types__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE__ux_button__, __WEBPACK_EXTERNAL_MODULE__ux_modal__, __WEBPACK_EXTERNAL_MODULE__ux_spinner__, __WEBPACK_EXTERNAL_MODULE__ux_growl__, __WEBPACK_EXTERNAL_MODULE__ux_text__) => {
return 